import React, { useState, useRef } from "react"
import { graphql } from "gatsby"

import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import axios from "axios"
// import ShareButtons from "../components/utils/ShareButtons"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Phone from "../images/phone2.png"
import Why1 from "../images/why-1.png"
import Why12 from "../images/why-1.2.png"
import Why2 from "../images/why-2.png"
import Why22 from "../images/why-2.2.png"
import Why3 from "../images/why-3.png"
import Why32 from "../images/why-3.2.png"

export const query = graphql`
  query BookQuery($id: String!) {
    strapiBook(strapiId: { eq: $id }) {
      strapiId
      title
      author
      content
      image
      link
      subtitle
      summary
      price
      audience
      faqs {
        question
        answer
      }
    }
  }
`
const Header = props => {
  const article = props.article
  return (
    <section className="hero is-dark is-small">
      <span style={{ position: "absolute", top: "0", left: "0" }}>
        <svg
          width="593"
          height="476"
          viewBox="0 0 593 476"
          fill="none"
          style={{ maxWidth: "100%" }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M477.856 -42.2189L482.182 19.0265C486.152 79.4857 495.336 203.156 451.472 209.496C407.964 216.622 312.295 108.383 278.206 136.341C244.471 165.086 271.427 328.062 242.403 367.223C213.378 406.384 126.595 317.798 64.4265 283.653C1.90223 248.72 -36.8969 266.263 -56.2965 275.034L-75.696 283.805L-161 95.1333L-141.6 86.3622C-122.201 77.5911 -83.4017 60.0489 -44.6025 42.5067C-5.80341 24.9644 32.9957 7.42222 71.7949 -10.12C110.594 -27.6622 149.393 -45.2044 188.192 -62.7467C226.991 -80.2889 265.791 -97.8311 304.59 -115.373C343.389 -132.916 382.188 -150.458 401.588 -159.229L420.987 -168L477.856 -42.2189Z"
            fill="#11183A"
          />
        </svg>
      </span>
      <div className="hero-body is-front">
        <div className="container pt-6 pb-6">
          <div className="columns is-vcentered">
            <div className="column is-6 content">
              <p className="has-text-white has-opacity-65 is-marginless">
                Actionable skills and habits from {article.author}'s
              </p>
              <h1 className="is-size-2 has-text-weight-bold has-text-white title-font is-marginless">
                {article.title}
                {/* How to Win Friends & Influence People */}
              </h1>

              <div className="has-text-white mt-4">
                <ReactMarkdown source={article.subtitle} />
              </div>

              <div className="has-background-darker mt-5 has-text-white has-border-round">
                <div className="px-5 pt-5 pb-2">
                  <div className="mb-3">
                    <p className="">
                      <span
                        className="has-opacity-65 is-size-3"
                        style={{ textDecoration: "line-through" }}
                      >
                        ${article.price}
                      </span>{" "}
                      <span className="has-text-weight-bold is-size-3 mx-2">
                        FREE
                      </span>{" "}
                      <span className="is-size-7 has-opacity-65">
                        (limited offer)
                      </span>
                    </p>
                  </div>

                  <div>
                    {article.summary
                      ? article.summary
                          .split("\n")
                          .map((articleItem, index) => {
                            return (
                              <ListItem key={index} content={articleItem} />
                            )
                          })
                      : ""}
                  </div>
                </div>

                <CTAForm article={article} />
              </div>
            </div>
            <div className="column is-6 has-text-centered">
              <CustomPhoneImg className="is-relative">
                {article.image ? (
                  <img
                    src={article.image}
                    alt={article.image}
                    className="has-border-round"
                  />
                ) : (
                  ""
                )}
                <img src={Phone} alt="" />
              </CustomPhoneImg>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CTAForm = props => {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const inputEl = useRef(null)

  const submit = e => {
    e.preventDefault()

    let hsData = {
      // submittedAt: "1517927174000",
      fields: [
        {
          name: "email",
          value:
            inputEl.current && inputEl.current.value
              ? inputEl.current.value
              : "",
        },
      ],
      context: {
        // hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: typeof window !== `undefined` ? window.location.href : "Local",
        pageName: props.article.title,
      },
    }

    function popupWindow(url, windowName, win, w, h) {
      const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
      const x = win.top.outerWidth / 2 + win.top.screenX - w / 2
      return win.open(
        url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      )
    }
    if (
      // .trim + .toLowercase()
      /^[0-9?A-z0-9?]+(\.)?[0-9?A-z0-9?]+@[A-z]+\.[A-z]{1,3}.?[A-z]{0,3}$/.test(
        inputEl.current.value
      )
    ) {
      popupWindow(
        props.article.link +
          "&emailId=" +
          inputEl.current.value.trim().toLowerCase(),
        "Enrollment successful",
        window,
        400,
        400
      )
      axios
        .post(
          "https://api.hsforms.com/submissions/v3/integration/submit/3431333/f904ee09-1544-429e-b402-a27d792f62ee",
          hsData
        )
        .then(function (response) {
          // console.log(response)
        })
        .catch(function (error) {
          // console.log(error)
        })
      setError("")
      setSuccess("Congrats! You'll receive an email with further instructions")
    } else {
      setError("Please make sure you type in a valid email")
      setSuccess("")
    }

    // axios
    //   .post(process.env.API_URL + "/enroll")
    //   .then(function (res) {
    //     console.log(res)
    //   })
    //   .catch(function (error) {
    //     console.log(error)
    //   })
    // if (
    //   // .trim + .toLowercase()
    //   /^[0-9?A-z0-9?]+(\.)?[0-9?A-z0-9?]+@[A-z]+\.[A-z]{1,3}.?[A-z]{0,3}$/.test(
    //     inputEl.current.value
    //   )
    // ) {
    // }
  }

  return (
    <form onSubmit={e => submit(e)}>
      <div className="field has-addons is-marginless">
        <div className="control" style={{ width: "100%" }}>
          <input
            type="email"
            className={
              error ? "input is-fullwidth is-danger" : "input is-fullwidth"
            }
            placeholder="Your email address"
            ref={inputEl}
          />
        </div>
        <div className="control is-relative">
          <button className="button is-success" type="submit">
            Get started for free
          </button>
          <span
            className="is-size-7"
            style={{
              position: "absolute",
              bottom: "-20px",
              left: "calc(1.5em - 1px)",
            }}
          >
            *no credit card required
          </span>
        </div>
      </div>
      {error ? <p>{error}</p> : ""}
      {success ? <p>{success}</p> : ""}
    </form>
  )
}

const AudienceSection = props => {
  const colors = [
    "green",
    "teal",
    "yellow",
    "red",
    "blue",
    "purple",
    "green",
    "teal",
    "yellow",
    "red",
    "blue",
    "purple",
  ]
  return (
    <section className="section mt-5">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold title-font is-size-3 ">
              Who is this for?
            </p>
          </div>
        </div>
        <div className="columns is-multiline">
          {props.audience
            ? props.audience.split("\n").map((audienceItem, index) => {
                return (
                  <div className="column is-4" key={index}>
                    <p className="pr-3 who-item is-relative mb-6">
                      <span
                        className={"has-background-" + colors[index] + "-100"}
                      ></span>
                      {audienceItem}
                    </p>
                  </div>
                )
              })
            : ""}
        </div>
      </div>
    </section>
  )
}

const WhySection = props => {
  return (
    <div className="has-background-grey-50 is-relative">
      <WaveDetail
        style={{
          top: "-100px",
          bottom: "auto",
          backgroundColor: "#f4f7fc",
          transform: "rotate(180deg)",
        }}
      />
      <section className="section">
        <div className="container pt-5">
          <div className="columns mb-5">
            <div className="column is-6">
              <p className="has-text-weight-bold title-font is-size-3 ">
                Why learn on BookDood?
              </p>
              {/* <p>
                  We curate books into actionable courses that facilitates
                  better learning and provides way to instantly change how you
                  work, live, and think.
                </p> */}
              <p>
                BookDood makes great books ‘talk’ to you and guide you through
                the right actions for your life! It curates the best ideas out
                of them and creates a guided journey for you to grow and expand
                your thinking!
              </p>
            </div>
          </div>
          <div className="columns my-6">
            <div className="column is-4 has-text-centered px-6">
              <CustomWhy className="has-text-centered mb-3 is-relative">
                <img src={Why1} alt="" />
                <span>
                  <img src={Why12} alt="" />
                </span>
              </CustomWhy>
              <p className="mt-5">
                Don’t just passively read books ever again, put them to action
              </p>
            </div>
            <div className="column is-4 has-text-centered px-6">
              <CustomWhy className="has-text-centered mb-3 is-relative">
                <img src={Why2} alt="" />
                <span
                  style={{
                    top: "auto",
                    bottom: "-16px",
                    right: "-40px",
                  }}
                >
                  <img src={Why22} alt="" />
                </span>
              </CustomWhy>
              <p className="mt-5">
                Don’t ever read books alone, bring others like you into the
                journey
              </p>
            </div>
            <div className="column is-4 has-text-centered px-6">
              <CustomWhy className="has-text-centered mb-3 is-relative">
                <img src={Why3} alt="" />
                <span>
                  <img src={Why32} alt="" />
                </span>
              </CustomWhy>
              <p className="mt-5">
                Don’t ever find reading monotone again, get engaged with
                interactive content or take notes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const ListItem = props => {
  return (
    <div className="mb-5">
      <p className="has-text-white is-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="is-24 top-6px mr-2"
          viewBox="0 0 50 50"
          fill="#e96463"
        >
          <path
            d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562 L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036 c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"
            fill="#e96463"
          />
        </svg>
        {props.content}
      </p>
    </div>
  )
}

const FAQ = props => {
  const [open, setOpen] = useState(false)
  return (
    <CustomCard
      className="has-background-white card mb-5 has-border-round"
      onClick={() => setOpen(!open)}
    >
      <div className="card-content">
        <div className="is-flex" style={{ justifyContent: "space-between" }}>
          <p className="has-text-weight-bold is-size-5">
            {props.article.question ? props.article.question : ""}
          </p>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transition: "all .25s ease",
              transform: open ? "rotate(45deg)" : "rotate(0)",
            }}
          >
            <path
              d="M16 1.28003C7.88291 1.28003 1.28003 7.88291 1.28003 16C1.28003 24.1171 7.88291 30.72 16 30.72C24.1171 30.72 30.72 24.1171 30.72 16C30.72 7.88291 24.1171 1.28003 16 1.28003ZM20.48 16.64H16.64V20.48C16.64 20.8339 16.3539 21.12 16 21.12C15.6461 21.12 15.36 20.8339 15.36 20.48V16.64H11.52C11.1661 16.64 10.88 16.3539 10.88 16C10.88 15.6461 11.1661 15.36 11.52 15.36H15.36V11.52C15.36 11.1661 15.6461 10.88 16 10.88C16.3539 10.88 16.64 11.1661 16.64 11.52V15.36H20.48C20.8339 15.36 21.12 15.6461 21.12 16C21.12 16.3539 20.8339 16.64 20.48 16.64Z"
              fill="#181F40"
            />
          </svg>
        </div>
        {open ? (
          <div className="content mt-4">
            {props.article.answer ? (
              <ReactMarkdown source={props.article.answer} />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </CustomCard>
  )
}

const Article = ({ data }) => {
  const article = data.strapiBook
  // console.log(article)
  // const title = article.title
  // const url = typeof window !== `undefined` ? window.location.href : ""
  // const twitterHandle = "sabo_radu94"
  // const image = article.image
  // const summary = article.content

  return (
    <Layout>
      <Seo title={article.title} image={article.image} />
      <Header article={article} />

      <div className="is-relative">
        <WaveDetail
          style={{
            top: "-170px",
            bottom: "auto",
            transform: "rotate(180deg)",
            zIndex: "1",
          }}
        />
        <div className="has-background-darker">
          <section className="section pt-0">
            <div className="">
              <div className="columns is-centered testimonials">
                <div className="column is-4-desktop is-9-mobile has-text-centered pb-6">
                  {/* <p className="has-opacity-65 is-size-7">
                    Mike Scott1, Business owner
                  </p> */}
                  <p className="title-font has-text-weight-bold is-size-3">
                    “Reading is no longer painful”
                  </p>
                  <p>
                    "Bookdood brought me new ideas, new friends, new
                    perspectives. Reading is no longer painful, It’s the one
                    activity I look forward to doing everyday!"
                  </p>
                </div>
                <div className="column is-1"></div>
                <div className="column is-4-desktop is-9-mobile has-text-centered pb-6">
                  {/* <p className="has-opacity-65 is-size-7">
                    John Wong2, Team Leader
                  </p> */}
                  <p className="title-font has-text-weight-bold is-size-3">
                    “I read for change and it shows up in my life”
                  </p>
                  <p>
                    “It’s like having a coach while you are reading! I no longer
                    just read for knowledge, I read for change and it shows up
                    in my life”
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <AudienceSection audience={article.audience} />

      <div className="mb-5 is-front">
        <div className="container">
          <div className="columns">
            <div className="column is-7 has-text-white">
              <div className="has-background-dark px-6 py-6 has-border-round">
                <p className="has-text-weight-bold title-font is-size-2">
                  Feels like you? Yeah, we feel you.
                </p>
                <p className="my-4">
                  Take a leap and change for the better. No risk. No commitment.
                </p>
                <CTAForm article={article} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WhySection />

      <section className="section has-background-dark">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <p className="has-text-white has-text-weight-bold title-font is-size-3 ">
                Frequently Asked Questions
              </p>
            </div>
          </div>

          <div className="columns is-vcentered">
            <div className="column is-7">
              {article.faqs
                ? article.faqs.map((faq, index) => {
                    return <FAQ key={index} article={faq} />
                  })
                : ""}
            </div>
            <div className="column is-5 has-text-centered">
              <p className="has-text-white is-size-4 mb-3">
                Still have questions?
              </p>
              <a
                href="mailto:radu@growkarma.io"
                target="_blank"
                rel="noreferrer"
                className="button is-success"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-dark has-background-darker is-small is-front">
        <WaveDetail
          style={{
            top: "-100px",
            bottom: "auto",
            transform: "rotate(180deg)",
          }}
        />
        <span style={{ position: "absolute", top: "0", left: "0" }}>
          <svg
            width="593"
            height="476"
            viewBox="0 0 593 476"
            fill="none"
            style={{ maxWidth: "100%" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M477.856 -42.2189L482.182 19.0265C486.152 79.4857 495.336 203.156 451.472 209.496C407.964 216.622 312.295 108.383 278.206 136.341C244.471 165.086 271.427 328.062 242.403 367.223C213.378 406.384 126.595 317.798 64.4265 283.653C1.90223 248.72 -36.8969 266.263 -56.2965 275.034L-75.696 283.805L-161 95.1333L-141.6 86.3622C-122.201 77.5911 -83.4017 60.0489 -44.6025 42.5067C-5.80341 24.9644 32.9957 7.42222 71.7949 -10.12C110.594 -27.6622 149.393 -45.2044 188.192 -62.7467C226.991 -80.2889 265.791 -97.8311 304.59 -115.373C343.389 -132.916 382.188 -150.458 401.588 -159.229L420.987 -168L477.856 -42.2189Z"
              fill="#11183A"
            />
          </svg>
        </span>
        <div className="hero-body">
          <div className="container pt-6 pb-6">
            <div className="columns is-vcentered">
              <div className="column is-6 content">
                <p className="has-text-white has-opacity-65 is-marginless">
                  {article.title}
                </p>
                <h1 className="is-size-2 has-text-weight-bold title-font has-text-white mb-3 mt-1">
                  Translate knowledge from books into{" "}
                  <span className="has-text-success">skills</span> and{" "}
                  <span className="has-text-danger">habits</span>.
                </h1>

                <div className="has-background-dark has-text-white has-border-round">
                  <div className="px-5 pt-5 pb-2">
                    <div className="mb-3">
                      <p className="">
                        <span
                          className="has-opacity-65 is-size-3"
                          style={{ textDecoration: "line-through" }}
                        >
                          ${article.price}
                        </span>{" "}
                        <span className="has-text-weight-bold is-size-3 mx-2">
                          FREE
                        </span>{" "}
                        <span className="is-size-7 has-opacity-65">
                          (limited offer)
                        </span>
                      </p>
                    </div>

                    <div>
                      {article.summary
                        ? article.summary
                            .split("\n")
                            .map((articleItem, index) => {
                              return (
                                <ListItem key={index} content={articleItem} />
                              )
                            })
                        : ""}
                    </div>
                  </div>

                  <CTAForm article={article} />
                </div>
              </div>
              <div className="column is-6 has-text-centered is-hidden-mobile">
                <CustomPhoneImg className="is-relative">
                  {article.image ? (
                    <img
                      src={article.image}
                      alt={article.image}
                      className="has-border-round"
                    />
                  ) : (
                    ""
                  )}
                  <img src={Phone} alt="" />
                </CustomPhoneImg>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section">
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-8">
                    <div className="content">
                      <ReactMarkdown source={summary} />
                    </div>
                    <hr />
                    <div
                      className="is-flex"
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="has-opacity-65 my-0">
                        Added on{" "}
                        <Moment format="MMM Do YYYY">
                          {article.published_at}
                        </Moment>
                      </p>
                    </div>
                    <hr />
                    <div className="content">
                      <p>Help this book gain popularity</p>
                      <ShareButtons
                        title={title}
                        url={url}
                        twitterHandle={twitterHandle}
                        tags={["books"]}
                        summary={summary}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
    </Layout>
  )
}

export default Article

const WaveDetail = styled.div`
  clip-path: polygon(
    100% 0,
    0 0,
    0 77.5%,
    1% 77.4%,
    2% 77.1%,
    3% 76.6%,
    4% 75.9%,
    5% 75.05%,
    6% 74.05%,
    7% 72.95%,
    8% 71.75%,
    9% 70.55%,
    10% 69.3%,
    11% 68.05%,
    12% 66.9%,
    13% 65.8%,
    14% 64.8%,
    15% 64%,
    16% 63.35%,
    17% 62.85%,
    18% 62.6%,
    19% 62.5%,
    20% 62.65%,
    21% 63%,
    22% 63.5%,
    23% 64.2%,
    24% 65.1%,
    25% 66.1%,
    26% 67.2%,
    27% 68.4%,
    28% 69.65%,
    29% 70.9%,
    30% 72.15%,
    31% 73.3%,
    32% 74.35%,
    33% 75.3%,
    34% 76.1%,
    35% 76.75%,
    36% 77.2%,
    37% 77.45%,
    38% 77.5%,
    39% 77.3%,
    40% 76.95%,
    41% 76.4%,
    42% 75.65%,
    43% 74.75%,
    44% 73.75%,
    45% 72.6%,
    46% 71.4%,
    47% 70.15%,
    48% 68.9%,
    49% 67.7%,
    50% 66.55%,
    51% 65.5%,
    52% 64.55%,
    53% 63.75%,
    54% 63.15%,
    55% 62.75%,
    56% 62.55%,
    57% 62.5%,
    58% 62.7%,
    59% 63.1%,
    60% 63.7%,
    61% 64.45%,
    62% 65.4%,
    63% 66.45%,
    64% 67.6%,
    65% 68.8%,
    66% 70.05%,
    67% 71.3%,
    68% 72.5%,
    69% 73.6%,
    70% 74.65%,
    71% 75.55%,
    72% 76.35%,
    73% 76.9%,
    74% 77.3%,
    75% 77.5%,
    76% 77.45%,
    77% 77.25%,
    78% 76.8%,
    79% 76.2%,
    80% 75.4%,
    81% 74.45%,
    82% 73.4%,
    83% 72.25%,
    84% 71.05%,
    85% 69.8%,
    86% 68.55%,
    87% 67.35%,
    88% 66.2%,
    89% 65.2%,
    90% 64.3%,
    91% 63.55%,
    92% 63%,
    93% 62.65%,
    94% 62.5%,
    95% 62.55%,
    96% 62.8%,
    97% 63.3%,
    98% 63.9%,
    99% 64.75%,
    100% 65.7%
  );
  background-color: #11183a;
  height: 180px;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  z-index: -1;
`

const CustomCard = styled.div`
  cursor: pointer;
`

const CustomWhy = styled.div`
  & > img {
    max-width: 300px;
  }
  span {
    position: absolute;
    top: -20px;
    right: -40px;
    img {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      display: block;
    }
  }
`

const CustomPhoneImg = styled.div`
  img:first-child {
    position: absolute;
    width: 190px;
    z-index: 2;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.4);
    animation: float2 8s ease-in-out infinite;
  }
  img:nth-of-type(2) {
    max-width: 480px;
    animation: float 10s ease-in-out infinite;
  }
  @keyframes float {
    0% {
      filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
      transform: translatey(0px);
    }
    50% {
      filter: drop-shadow(0 25px 15px 0px rgba(0, 0, 0, 0.2));
      transform: translatey(-10px);
    }
    100% {
      filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
      transform: translatey(0px);
    }
  }
  @keyframes float2 {
    0% {
      filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
      transform: rotate(-24deg) translatey(0px);
    }
    50% {
      filter: drop-shadow(0 25px 15px 0px rgba(0, 0, 0, 0.2));
      transform: rotate(-24deg) translatey(-6px);
    }
    100% {
      filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
      transform: rotate(-24deg) translatey(0px);
    }
  }
`
